import { IonPage } from "@ionic/react";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { BonusList, PayrollLineItem } from "@src/lib/interface";
import { groupBy, uniq } from "lodash";
import { useCallback, useEffect, useState } from "react";

import {
  fetchBalance,
  fetchLifeTimeEarnings,
  fetchPayrollData,
  getClipboardPaySchedule,
  getShiftsDetailsForBonuses,
} from "./api";
import { BONUS_TYPE } from "./constants";
import { useIsProofOfEarningsRevampEnabled } from "./featureFlagHooks";
import { Balance, ClipboardPaySchedule, ShiftDetailForBonus } from "./models";
import { PayrollSummary } from "./summary";
import { formatBonuses } from "./utils";
import { USER_EVENTS } from "../../constants/userEvents";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useDefinedAgent } from "../store/helperHooks";

export function PayrollNewPage() {
  const agent = useDefinedAgent();
  const agentTimezone = agent.tmz || "America/Los_Angeles";

  const [loading, setLoading] = useState<boolean>(true);
  const [balance, setBalance] = useState<Balance>();
  const [payroll, setPayroll] = useState<PayrollLineItem[]>([]);
  const [currentPaySchedule, setCurrentPaySchedule] = useState<ClipboardPaySchedule | null>(null);
  const [relativeWeek, setRelativeWeek] = useState<number>(0);
  const [earnings, setEarnings] = useState<number | undefined>(0);

  const isProofOfEarningsRevampEnabled = useIsProofOfEarningsRevampEnabled();

  const { showErrorToast } = useToast();

  const getPayrollData = useCallback(async () => {
    try {
      const { shifts, bonuses } = await fetchPayrollData({
        relativeWeek,
      });
      const flatBonuses = bonuses.flatMap((bonus) =>
        bonus.type === BONUS_TYPE.TBIO_SINGLE ? [bonus.shiftId!] : bonus.group!
      );
      const shiftIds: string[] = uniq(flatBonuses.filter((bonus) => !!bonus)); // filter undefined and null shiftIds
      const shiftDetails: ShiftDetailForBonus[] = await getShiftsDetailsForBonuses(
        shiftIds,
        "name facility.tmz facility.name start end"
      );

      const formattedBonuses: BonusList[] = formatBonuses({
        bonuses,
        shiftDetails,
        agentTimezone,
      });
      const filteredBonuses = formattedBonuses.filter((bonus) => bonus.status !== "REVERSED");

      const payrollLineItems: PayrollLineItem[] = [];
      const lineItemsByShiftId = groupBy(filteredBonuses, "shiftId");
      shifts.forEach((shift) => {
        payrollLineItems.push({ type: "SHIFT", ...shift });
        if (lineItemsByShiftId[shift._id]) {
          payrollLineItems.push(...lineItemsByShiftId[shift._id]);
          delete lineItemsByShiftId[shift._id];
        }
      });
      payrollLineItems.push(...(Object.values(lineItemsByShiftId).flat() as PayrollLineItem[]));
      setPayroll(payrollLineItems);
    } catch (error) {
      console.error(error);
      showErrorToast("Failed to get payment history data.");
    }
  }, [relativeWeek, agentTimezone, showErrorToast]);

  const fetchBalanceAndLifeTimeEarnings = useCallback(async () => {
    try {
      setLoading(true);
      const [balanceResponse, payScheduleResponse, lifetimeEarningsResponse] = await Promise.all([
        fetchBalance(),
        getClipboardPaySchedule(agent.userId!),
        fetchLifeTimeEarnings(),
      ]);
      setBalance(balanceResponse);
      setCurrentPaySchedule(payScheduleResponse);
      setEarnings(lifetimeEarningsResponse.summary.total);
      setLoading(false);
    } catch (error) {
      console.error(error);
      showErrorToast("Failed to get balance data.");
    }
  }, [agent.userId, showErrorToast]);

  useEffect(() => {
    void fetchBalanceAndLifeTimeEarnings();
  }, [fetchBalanceAndLifeTimeEarnings]);

  useEffect(() => {
    void getPayrollData();
  }, [relativeWeek, getPayrollData]);

  useLogEffect(USER_EVENTS.VIEWED_PAYROLL);

  const handleBackClick = () => {
    setRelativeWeek(relativeWeek - 1);
  };
  const handleForwardClick = () => {
    setRelativeWeek(relativeWeek + 1);
  };

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={isProofOfEarningsRevampEnabled ? "Earnings" : "Payroll"}
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.ACCOUNT} />}
          />
        }
        onRefresh={async () => {
          await Promise.all([fetchBalanceAndLifeTimeEarnings(), getPayrollData()]);
        }}
      >
        <PayrollSummary
          handleBackClick={handleBackClick}
          handleForwardClick={handleForwardClick}
          items={payroll}
          balance={balance ?? ({} as Balance)}
          lifetimeEarnings={earnings!}
          currentPaySchedule={currentPaySchedule}
          onPayScheduleChange={setCurrentPaySchedule}
          setBalance={setBalance}
          relativeWeek={relativeWeek}
          loading={loading}
        />
      </PageWithHeader>
    </IonPage>
  );
}
